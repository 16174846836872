import chunk from "lodash/chunk";
import compact from "lodash/compact";
import flatMap from "lodash/flatMap";
import isArray from "lodash/isArray";
import snakeCase from "lodash/snakeCase";
import uniq from "lodash/uniq";
import * as queryString from "query-string";
import { IHttpService } from "../services/HttpService";
import {
  AdLabelsCreateResource,
  AdLabelsIndexResource,
  AdLabelUpdateRequestResource,
  AudienceAttributesIndexResource,
  AudienceEventResource,
  AudienceEventUpdateRequestResource,
  AudiUserResource,
  AutoBidMultiplierAttributes,
  BatchDeleteResource,
  BidMultiplierAttrType,
  BidMultiplierCreateResource,
  BidMultiplierParentType,
  BidMultiplierResource,
  BidMultipliersImportJobResource,
  BidMultiplierUpdateResource,
  BillingAccountsResource,
  BudgetMonthlyKpisResource,
  BudgetPlanShowResource,
  BudgetPlanUpdateResource,
  BudgetPoolBatchUpdateRequest,
  BudgetPoolBatchUpdateResponse,
  BudgetPoolCreateResource,
  BudgetPoolsIndexResource,
  BudgetPoolsOverviewResource,
  BudgetPoolsShowResource,
  BudgetPoolUpdateRequestResource,
  BudgetSpendRateResource,
  CategoriesIndexResource,
  CsvUpload,
  CsvUploadType,
  CurrencyResource,
  DeviceBlocklistBatchCreateRequest,
  DeviceBlocklistBatchCreateResponse,
  DeviceBlocklistCreateResource,
  DeviceBlocklistIndexResource,
  EntitiesResource,
  EntitiesUpdateRequestResource,
  EventApiUserCreateResource,
  EventApiUserResource,
  EventApiUserShowResource,
  EventApiUserUpdateResource,
  FlightSelectorResource,
  ForecastCreateResource,
  ForecastResource,
  FutureOrderLineItemCreateResource,
  ImpactReportCreateResource,
  ImpactReportResource,
  InventorySplitCreateResource,
  InventorySplitResource,
  InventorySplitUpdateResource,
  InvoiceLineItemsCreateResource,
  InvoiceLineItemsResource,
  InvoiceLineItemsUpdateResource,
  InvoicesCreateRequestResource,
  InvoicesCreateResponseResource,
  InvoicesIndexResource,
  InvoiceStateValue,
  InvoicesUpdateRequestResource,
  IPBlocklistBatchCreateRequest,
  IPBlocklistBatchCreateResponse,
  IPBlocklistCreateResource,
  IPBlocklistIndexResource,
  ISessionCreateAttributes,
  OrderLineItemsCreateResource,
  OrderLineItemsIndexResource,
  OrderLineItemsIndexResponse,
  OrderLineItemsUpdateResource,
  OrganizationsIndexResponse,
  OrganizationsShowResource,
  OrganizationsUpdateRequestResource,
  PlatformFeeDealCreateResource,
  PlatformFeeDealResource,
  PlatformFeeDealUpdateResource,
  PredictionFlightCreateRequestResource,
  PredictionFlightCreateResponseResource,
  PredictionFlightResource,
  PredictionFlightUpdateRequestResource,
  PredictorConfigCreateRequestResource,
  PredictorConfigResource,
  PredictorConfigUpdateRequestResource,
  PublisherBlocklistBatchCreateRequest,
  PublisherBlocklistBatchCreateResponse,
  PublisherBlocklistCreateResource,
  PublisherBlocklistIndexResource,
  PublisherListCreateResource,
  PublisherListEntriesBatchCreateRequest,
  PublisherListEntriesIndexResource,
  PublisherListsIndexResource,
  PublisherListUpdateRequestResource,
  PublisherVerticalsIndexResource,
  S3LinkForAdPreviewResource,
  SearchResult,
  SkanCampaignCreateResource,
  SkanCampaignResource,
  SkanCampaignUpdateResource,
  SpendTierCreateResource,
  SpendTierResource,
  SpendTierUpdateResource,
  Status,
  StatusWithConcluded,
  SupplyPartnerModeValue,
  SupplyPartnersCreateResource,
  SupplyPartnersIndexResource,
  SupplyPartnersShowResource,
  SupplyPartnersUpdateRequestResource,
  UsersCreateRequestResource,
  UsersIndexResource,
  UsersShowResource,
  UsersUpdateRequestResource,
  VersionResource,
} from "./apiTypes";
import {
  AdContentType,
  AdsBatchCreateRequest,
  AdsBatchCreateResponse,
  AdsBatchUpdateRequest,
  AdsBatchUpdateResponse,
  AdsCopyToCampaignsRequestAttributes,
  AdsCopyToCampaignsResponse,
  AdsIndexParameters,
  AdsIndexResource,
  AdsUpdateRequestResource,
  AdType,
  NativeAdsBatchCreateRequest,
  NativeAdsBatchCreateResponse,
  TemplateAdsBatchCreateRequest,
  TemplateAdsBatchCreateResponse,
  VideoAdsBatchCreateRequest,
  VideoAdsBatchCreateResponse,
  VideoAdsUpdateResource,
} from "./apiTypes/ad";
import {
  AdAssetCreateResource,
  AdAssetResource,
  AdAssetType,
  AdBiddingResponse,
  AdsAssetUpdateRequestResource,
} from "./apiTypes/adAsset";
import { AdLabelResource, AdLabelsBatchUpdateRequest, AdLabelsBatchUpdateResponse } from "./apiTypes/adLabel";
import {
  AdLayoutDefaultValues,
  AdLayoutExampleTemplateResource,
  AdLayoutInstanceCreateResource,
  AdLayoutInstanceExampleTemplateResource,
  AdLayoutInstanceResource,
  AdLayoutInstanceUpdateResource,
  AdLayoutParameterValue,
  AdLayoutResource,
  CreativeSetCreateResource,
  CreativeSetResource,
  CreativeSetUpdateResource,
} from "./apiTypes/adLayout";
import {
  AudienceEventProcessingCostResource,
  AudienceEventUploadJobIndexResource,
  AudienceResource,
  AudienceShowResource,
  AudienceSizeResource,
  AudienceUpdateRequestResource,
  AudienceVerticalsResource,
} from "./apiTypes/audience";
import {
  CampaignBiddingReportResource,
  CampaignCopyResponse,
  CampaignExperimentBudgetSuggestionResource,
  CampaignExperimentReccomendationResource,
  CampaignsBatchUpdateRequest,
  CampaignsBatchUpdateResponse,
  CampaignsCopyRequestAttributes,
  CampaignsCreateResource,
  CampaignsIndexResource,
  CampaignSpendResource,
  CampaignsShowResource,
  CampaignsUpdateRequestResource,
  CampaignType,
} from "./apiTypes/campaign";
import {
  CampaignGroupCreateResource,
  CampaignGroupResource,
  CampaignGroupUpdateRequestResource,
  MarketingStrategy,
} from "./apiTypes/campaignGroup";
import { CreativeSetIndexResponse, LinkForCreativeSetPreviewResource } from "./apiTypes/creativeSet";
import {
  AdTypeBreakdownAttributes,
  CampaignBreakdownAttributes,
  DruidOverviewResponse,
  DruidResponse,
  InventoryBreakdownAttributes,
  OverviewAttributes,
} from "./apiTypes/dashboardApiTypes";
import {
  ExperimentCreateResource,
  ExperimentDeviceModelGroups,
  ExperimentGroupResource,
  ExperimentGroupSplitResource,
  ExperimentResource,
  ExperimentResults,
  ExperimentTypeValue,
  ExperimentUpdateRequestResource,
  InterventionCreateResource,
  InterventionResource,
  InterventionUpdateRequestResource,
} from "./apiTypes/experiment";
import { FeedIndexResource, FeedsPreview } from "./apiTypes/feed";
import {
  ForkedExperimentCreateResource,
  ForkedExperimentCurrentMetrics,
  ForkedExperimentResource,
} from "./apiTypes/forkedExperiment";
import {
  GlobalExperimentCreateResource,
  GlobalExperimentPivotUrl,
  GlobalExperimentResource,
  GlobalExperimentResults,
  GlobalExperimentSetCreateResource,
  GlobalExperimentSetResource,
  GlobalExperimentSetUpdateRequestResource,
  GlobalExperimentUpdateRequestResource,
} from "./apiTypes/globalExperiment";
import {
  RuleCreateResource,
  RulesIndexResource,
  RulesSetUpdateResource,
  RulesSetUpdateResponse,
  RuleUpdateAttributes,
} from "./apiTypes/rule";
import {
  SegmentSizeResource,
  SegmentsResource,
  UserListImportCreateResource,
  UserListImportIndexResource,
  UserListImportJobIndexResponse,
  UserListImportResource,
  UserListImportUpdateRequestResource,
} from "./apiTypes/segment";
import fetchInBatches from "./fetchInBatches";
import {
  BatchCreateResponse,
  BatchDeleteResponse,
  ICollectionDocument,
  ICollectionDocumentWithMeta,
  ISingularDocument,
  PersistedResourceObject,
  ResourceId,
} from "./jsonApi";

/*
 * Serializes query params to a get string. Automatically strips `undefined`, `null`, and
 * `false`. `fasle` is parsed by Rails to `"false"` and therefore a truthy value.
 * If you want to explicity encode two options use a concerete values or `"yes"` and `"no"`
 */
export function encodeQueryParams(params: Record<string, any>): string {
  const processedQueryParams = Object.entries(params).reduce(
    (result, [key, value]) => {
      if (value === false || value === null) return result;

      result[snakeCase(key)] = isArray(value) ? uniq(value) : value;
      return result;
    },
    {} as Record<string, any>,
  );
  return queryString.stringify(processedQueryParams, { arrayFormat: "bracket" });
}

function bidMultiplierParentTypeToUrl(parentType: BidMultiplierParentType): string {
  return parentType === "Campaign" ? "campaigns" : "budget_pools";
}

type Method = "POST" | "GET" | "PATCH" | "DELETE" | "PUT";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type RemergeApiRequest<Response> = {
  url: string;
  method?: Method;
  body?: object | undefined;
};

const DEFAULT_BATCH_SIZE = 100;
export type BatchedCollectionGetRequest<Resource extends PersistedResourceObject<any, any>> = {
  requests: RemergeApiRequest<ICollectionDocument<Resource>>[];
};

function url(urlString: string, params?: object): string {
  return compact([urlString, params && encodeQueryParams(params)]).join("?");
}

function request<Response>(request: {
  url: string;
  method?: Method;
  body?: object | undefined;
}): RemergeApiRequest<Response> {
  const { url, method, body } = request;
  return { url, method: method || "GET", body: body || undefined };
}

function fetchFromRequestBuilder<Params extends Array<any>, Response>(
  requestBuilder: (...params: Params) => RemergeApiRequest<Response>,
): (...params: Params) => Promise<Response> {
  return function (this: RemergeApi, ...params: Params) {
    return this.fetchRequest(requestBuilder.call(this, ...params));
  };
}

export default class RemergeApi {
  constructor(private httpService: IHttpService) {
    this.httpService = httpService;
  }

  public adsIndex(
    params: AdsIndexParameters & { limit: number },
  ): Promise<ICollectionDocumentWithMeta<AdsIndexResource, { full_count: number }>>;
  public adsIndex(params: AdsIndexParameters): Promise<ICollectionDocument<AdsIndexResource>>;
  public adsIndex(params: AdsIndexParameters) {
    return this.fetch("/ads", { params }) as Promise<ICollectionDocument<AdsIndexResource>>;
  }

  public adsShow(id: string): Promise<ISingularDocument<AdsIndexResource>> {
    return this.fetch(`/ads/${id}`) as Promise<ISingularDocument<AdsIndexResource>>;
  }

  public getAdLabels(params: { organizationId: string }) {
    return this.fetch("/ad_labels", { params }) as Promise<ICollectionDocument<AdLabelResource>>;
  }

  public adLabelUpdate(data: AdLabelUpdateRequestResource): Promise<ISingularDocument<AdLabelResource>> {
    return this.fetch(`/ad_labels/${data.id}`, { body: { data }, method: "PATCH" });
  }

  public adsUpdate(ad: AdsUpdateRequestResource): Promise<ISingularDocument<AdsIndexResource>> {
    return this.fetch(`/ads/${ad.id}`, { body: { data: ad }, method: "PATCH" }) as Promise<
      ISingularDocument<AdsIndexResource>
    >;
  }

  public adPreview(id: string, params: { userId: string }): Promise<AdBiddingResponse> {
    return this.fetch(`/ads/${id}/preview`, { params }) as Promise<AdBiddingResponse>;
  }

  public adsCopyToCampaigns({
    ad_ids,
    campaign_ids,
  }: AdsCopyToCampaignsRequestAttributes): Promise<AdsCopyToCampaignsResponse> {
    return this.fetch("/ads/copy_to_campaigns", { body: { ad_ids, campaign_ids }, method: "POST" });
  }

  public s3LinkForAdPreview({
    adId,
    userId,
  }: {
    adId: string;
    userId: string;
  }): Promise<ISingularDocument<S3LinkForAdPreviewResource>> {
    return this.fetch(`/ads/${adId}/s3_link_for_preview`, { params: { userId } });
  }

  public adsBatchCreate(ads: AdsBatchCreateRequest): Promise<AdsBatchCreateResponse> {
    const legacyRequestBody = ads.map(ad => ({
      ...ad.attributes,
      campaign_id: ad.relationships.campaign.data.id,
      label_id: ad.relationships.label.data.id,
      asset_ids: ad.relationships.assets.data.map(relationship => relationship.id),
    }));
    return this.fetch(`/ads/batch`, { body: { data: legacyRequestBody }, method: "POST" });
  }

  public adsBatchUpdate(updates: AdsBatchUpdateRequest): Promise<AdsBatchUpdateResponse> {
    return this.fetch(`/ads/batch`, { body: { data: updates }, method: "PATCH" });
  }

  public templateAdsBatchCreate(ads: TemplateAdsBatchCreateRequest): Promise<TemplateAdsBatchCreateResponse> {
    return this.fetch(`/template_ads/batch`, { body: { data: ads }, method: "POST" });
  }

  public videoAdsUpdate(videoAd: VideoAdsUpdateResource): Promise<ISingularDocument<VideoAdsUpdateResource>> {
    return this.fetch(`/video_ads/${videoAd.id}`, { body: { data: videoAd }, method: "PATCH" });
  }

  public videoAdsBatchCreate(ads: VideoAdsBatchCreateRequest): Promise<VideoAdsBatchCreateResponse> {
    return this.fetch(`/video_ads/batch`, { body: { data: ads }, method: "POST" });
  }

  public nativeAdsBatchCreate(ads: NativeAdsBatchCreateRequest): Promise<NativeAdsBatchCreateResponse> {
    return this.fetch(`/native_ads/batch`, { body: { data: ads }, method: "POST" });
  }

  public campaignGroupsIndexRequest(params: {
    organizationId?: string;
    audienceId?: string;
    marketingStrategy?: MarketingStrategy;
  }): RemergeApiRequest<ICollectionDocument<CampaignGroupResource>> {
    return request({ url: url("/campaign_groups", params) });
  }

  public campaignGroupShowRequest(id: string): RemergeApiRequest<ISingularDocument<CampaignGroupResource>> {
    return request({ url: url(`/campaign_groups/${id}`) });
  }

  public campaignGroupUpdate(
    data: CampaignGroupUpdateRequestResource,
  ): Promise<ISingularDocument<CampaignGroupResource>> {
    return this.fetch(`/campaign_groups/${data.id}`, { body: { data }, method: "PATCH" });
  }

  public campaignGroupCreate(data: CampaignGroupCreateResource): Promise<ISingularDocument<CampaignGroupResource>> {
    return this.fetch(`/campaign_groups`, { body: { data }, method: "POST" });
  }

  public audiencesIndexRequest(params: {
    organizationId?: string;
  }): RemergeApiRequest<ICollectionDocument<AudienceResource>> {
    return request({ url: url("/audiences", params) });
  }
  /** @deprecated */
  public audiencesIndex = fetchFromRequestBuilder(this.audiencesIndexRequest);

  public audiencesIconReload(id: string): Promise<ISingularDocument<AudienceShowResource>> {
    return this.fetch(`/audiences/${id}/icon_reload`, { body: { data: {} }, method: "PATCH" });
  }

  public audiencesIconUpload(id: string, icon_asset_id: string): Promise<ISingularDocument<AudienceShowResource>> {
    return this.fetch(`/audiences/${id}/icon_upload`, { body: { data: { icon_asset_id } }, method: "PATCH" });
  }

  /** @deprecated */
  public publisherVerticals(): Promise<{ data: string[] }> {
    return this.fetch(`/publisher_verticals`);
  }

  public publisherVerticalsIndexRequest(): RemergeApiRequest<PublisherVerticalsIndexResource> {
    return request({ url: url(`/publisher_verticals`) });
  }

  public adLayoutsIndex(
    params: { adType?: AdType; contentType?: AdContentType } = {},
  ): Promise<ICollectionDocument<AdLayoutResource>> {
    return this.fetch("/ad_layouts", { params });
  }

  public adLayoutsIndexRequest(
    params: { adType?: AdType; contentType?: AdContentType } = {},
  ): RemergeApiRequest<ICollectionDocument<AdLayoutResource>> {
    return request({ url: url(`/ad_layouts`, params) });
  }

  public adLayoutsDetaultValues(audienceId: string): RemergeApiRequest<AdLayoutDefaultValues | null> {
    return request({ url: url(`/ad_layouts/default_values`, { audience_id: audienceId }) });
  }

  public adLayoutsExampleTemplate({
    adLayoutId,
    parameterValues,
  }: {
    adLayoutId: string;
    parameterValues: AdLayoutParameterValue[];
  }): Promise<ISingularDocument<AdLayoutExampleTemplateResource>> {
    return this.fetch(`/ad_layouts/${adLayoutId}/example_template`, {
      method: "POST",
      body: {
        data: parameterValues,
      },
    });
  }

  public adLayoutsExampleTemplateRequest = ({
    adLayoutId,
    parameterValues,
  }: {
    adLayoutId: string;
    parameterValues: AdLayoutParameterValue[];
  }): RemergeApiRequest<ISingularDocument<AdLayoutExampleTemplateResource>> => {
    return request({
      url: `/ad_layouts/${adLayoutId}/example_template`,
      method: "POST",
      body: { data: parameterValues },
    });
  };

  public adLayoutInstancesCreate(
    adLayoutInstanceResource: AdLayoutInstanceCreateResource,
  ): Promise<ISingularDocument<AdLayoutInstanceResource>> {
    return this.fetch("/ad_layout_instances", { body: { data: adLayoutInstanceResource }, method: "POST" });
  }

  public creativeSetCreate(
    creativeSetResource: CreativeSetCreateResource,
  ): Promise<ISingularDocument<CreativeSetResource>> {
    return this.fetch("/creative_sets", { body: { data: creativeSetResource }, method: "POST" });
  }

  public creativeSetIndex(params: { organization_id: string }): Promise<ICollectionDocument<CreativeSetResource>> {
    return this.fetch("/creative_sets", { params });
  }

  public campaignsIndexRequest(params: {
    organizationId?: string | string[];
    audienceId?: string;
    ids?: string[] | null;
    status?: Status | Status[];
    campaign_type?: CampaignType[];
    experimentId?: string | string[];
    segment?: string | string[];
    eligible_for_experiment?: boolean;
    budgetPoolId?: string | string[];
    show_inactive?: boolean;
    os?: string;
    overlappingAdLabelIds?: string[];
  }) {
    return request<ICollectionDocument<CampaignsIndexResource>>({ url: url("/campaigns", params) });
  }

  public segmentSizeRequest(id: string): RemergeApiRequest<ISingularDocument<SegmentSizeResource>> {
    return request({ url: url(`/segments/${id}/size`) });
  }

  /** @deprecated */
  campaignsIndex = fetchFromRequestBuilder(this.campaignsIndexRequest);

  public campaignsCreate(campaignResource: CampaignsCreateResource): Promise<ISingularDocument<CampaignsShowResource>> {
    return this.fetch("/campaigns", { body: { data: campaignResource }, method: "POST" });
  }

  public campaignsShow(id: string): Promise<ISingularDocument<CampaignsShowResource>> {
    return this.fetch(`/campaigns/${id}`) as Promise<ISingularDocument<CampaignsShowResource>>;
  }

  public campaignsUpdate(campaign: CampaignsUpdateRequestResource): Promise<ISingularDocument<CampaignsShowResource>> {
    return this.fetch(`/campaigns/${campaign.id}`, {
      body: { data: campaign },
      method: "PATCH",
    }) as Promise<ISingularDocument<CampaignsShowResource>>;
  }

  public campaignsBiddingReportRequest(params: { campaignIds: string[] }) {
    return request<ICollectionDocument<CampaignBiddingReportResource>>({
      url: url("/campaigns/bidding_report", params),
    });
  }

  public applyWinnerSettings(
    winner_variant_id: string,
    experiment_type: string,
  ): Promise<ISingularDocument<CampaignsShowResource>> {
    return this.fetch(`/campaigns/${winner_variant_id}/apply_settings`, {
      body: { data: { experiment_type } },
      method: "PATCH",
    }) as Promise<ISingularDocument<CampaignsShowResource>>;
  }

  public campaignsBatchUpdate(
    updates: CampaignsBatchUpdateRequest,
    update_children?: boolean,
  ): Promise<CampaignsBatchUpdateResponse> {
    return this.fetch(`/campaigns/batch`, { body: { data: updates, update_children }, method: "PATCH" });
  }

  public campaignsCopy({
    ids,
    ...attributes
  }: CampaignsCopyRequestAttributes & { ids: string[] }): Promise<CampaignCopyResponse> {
    return this.fetch("/campaigns/copy", { body: { ids, campaign: attributes }, method: "PATCH" });
  }

  public campaignHistoricalSpend(
    id: string,
    number_of_days: number,
  ): Promise<ISingularDocument<CampaignSpendResource>> {
    return this.fetch(`/campaigns/${id}/historical_spend`, { body: { number_of_days }, method: "POST" });
  }

  public campaignExperimentFallbackBudgetSuggestion(
    id: string,
    number_of_days: number,
  ): Promise<ISingularDocument<CampaignExperimentBudgetSuggestionResource>> {
    return this.fetch(`/campaigns/${id}/experiment_fallback_budget_suggestion`, {
      body: { number_of_days },
      method: "POST",
    });
  }

  public campaignExperimentSuggestion(
    id: string,
  ): Promise<ISingularDocument<CampaignExperimentReccomendationResource>> {
    return this.fetch(`/campaigns/${id}/experiment_suggestion`) as Promise<
      ISingularDocument<CampaignExperimentReccomendationResource>
    >;
  }

  public bidMultipliersIndex(
    params: BidMultipliersIndexRequestParams,
  ): Promise<ICollectionDocument<BidMultiplierResource>> {
    const [requestParamType, ids] = Object.entries(params)[0];
    const fetchBatch = (batch: string[]) => {
      return this.fetch("/bid_multipliers", { params: { [requestParamType]: batch } });
    };

    return fetchInBatches(ids, fetchBatch);
  }

  public bidMultiplierCreate(
    parentId: string,
    parentType: BidMultiplierParentType,
    bidMultiplier: BidMultiplierCreateResource,
  ): Promise<ISingularDocument<BidMultiplierResource>> {
    return this.fetch(`/${bidMultiplierParentTypeToUrl(parentType)}/${parentId}/bid_multipliers`, {
      body: { data: bidMultiplier },
      method: "POST",
    });
  }

  public bidMultiplierUpdate(
    parentId: string,
    parentType: BidMultiplierParentType,
    bidMultiplier: BidMultiplierUpdateResource,
  ): Promise<ISingularDocument<BidMultiplierResource>> {
    return this.fetch(`/${bidMultiplierParentTypeToUrl(parentType)}/${parentId}/bid_multipliers/${bidMultiplier.id}`, {
      body: { data: bidMultiplier },
      method: "PATCH",
    });
  }

  public bidMultiplierDelete(
    parentId: string,
    parentType: BidMultiplierParentType,
    bidMultiplier: BidMultiplierResource,
  ): Promise<void> {
    return this.fetch(`/${bidMultiplierParentTypeToUrl(parentType)}/${parentId}/bid_multipliers/${bidMultiplier.id}`, {
      method: "DELETE",
    });
  }

  public autoBidMultiplierIndex(budgetPoolId: string): Promise<{ data: AutoBidMultiplierAttributes[] }> {
    return this.fetch(`/auto_bid_multipliers`, { params: { budget_pool_id: budgetPoolId } });
  }

  public autoBidMultiplierDimensionsIndex(): Promise<{ data: BidMultiplierAttrType[] }> {
    return this.fetch(`/auto_bid_multipliers/dimensions`);
  }

  public currenciesIndex = fetchFromRequestBuilder(this.currenciesIndexRequest);

  public currenciesIndexRequest(
    params: { ids?: string[] } = {},
  ): RemergeApiRequest<ICollectionDocument<CurrencyResource>> {
    return request({ url: url(`/currencies`, params) });
  }

  public currenciesShowRequest(id: string): RemergeApiRequest<ISingularDocument<CurrencyResource>> {
    return request({ url: url(`/currencies/${id}`) });
  }

  /** @deprecated */
  currenciesShow = fetchFromRequestBuilder(this.currenciesShowRequest);

  public entitiesIndex() {
    return this.fetch("/remerge_entities") as Promise<ICollectionDocument<EntitiesResource>>;
  }

  public entitiesUpdate(data: EntitiesUpdateRequestResource) {
    return this.fetch(`/remerge_entities/${data.id}`, { body: { data }, method: "PATCH" }) as Promise<
      ISingularDocument<EntitiesResource>
    >;
  }

  public entitesShow(id: string): Promise<ISingularDocument<EntitiesResource>> {
    return this.fetch(`/remerge_entities/${id}`);
  }

  public orderLineItemsIndexRequest(params: {
    ids?: string[];
    organizations_ids?: string[];
    start_date_gte?: string;
    end_date_lt?: string;
    budget_pool_id?: string;
  }): RemergeApiRequest<OrderLineItemsIndexResponse> {
    return request({ url: url("/order_line_items", params) });
  }

  /** @deprecated */
  public orderLineItemsIndex = fetchFromRequestBuilder(this.orderLineItemsIndexRequest);

  public orderLineItemsUpdate(
    data: OrderLineItemsUpdateResource,
  ): Promise<ISingularDocument<OrderLineItemsIndexResource>> {
    return this.fetch(`/order_line_items/${data.id}`, { body: { data }, method: "PATCH" });
  }

  public orderLineItemsCreate(
    data: OrderLineItemsCreateResource,
  ): Promise<ISingularDocument<OrderLineItemsIndexResource>> {
    return this.fetch(`/order_line_items`, { body: { data }, method: "POST" });
  }

  public orderLineItemsDelete(lineItem: OrderLineItemsIndexResource): Promise<void> {
    return this.fetch(`/order_line_items/${lineItem.id}`, { method: "DELETE" });
  }

  public createFutureOrderLineItem(
    data: FutureOrderLineItemCreateResource,
  ): Promise<ISingularDocument<OrderLineItemsIndexResource>> {
    return this.fetch(`/order_line_items/create_future_line_item`, { body: { data }, method: "POST" });
  }

  public getOrderLineItem(id: string): Promise<ISingularDocument<OrderLineItemsIndexResource>> {
    return this.fetch(`/order_line_items/${id}`);
  }

  public organizationsIndexRequest = (params?: {
    ids?: string[];
    campaignTypes?: CampaignType[];
  }): RemergeApiRequest<OrganizationsIndexResponse> => {
    return request({ url: url(`/organizations`, params) });
  };

  /** @deprecated */
  organizationsIndex = fetchFromRequestBuilder(this.organizationsIndexRequest);

  public organizationsShowRequest(id: string): RemergeApiRequest<ISingularDocument<OrganizationsShowResource>> {
    return request({ url: url(`/organizations/${id}`) });
  }

  /** @deprecated */
  organizationsShow = fetchFromRequestBuilder(this.organizationsShowRequest);

  public usersIndexRequest(
    params: { ids?: string[]; admin?: boolean } = {},
  ): RemergeApiRequest<ICollectionDocument<UsersIndexResource>> {
    return request({ url: url(`/users`, params) });
  }

  public adLayoutShowRequest(id: string): RemergeApiRequest<ISingularDocument<AdLayoutResource>> {
    return request({ url: `/ad_layouts/${id}` });
  }

  public adLayoutInstanceUpdate(
    data: AdLayoutInstanceUpdateResource,
  ): Promise<ISingularDocument<AdLayoutInstanceResource>> {
    return this.fetch(`/ad_layout_instances/${data.id}`, { body: { data }, method: "PATCH" });
  }

  /** @deprecated */
  usersIndex = fetchFromRequestBuilder(this.usersIndexRequest);

  public usersShow(id: string): Promise<ISingularDocument<UsersShowResource>> {
    return this.fetch(`/users/${id}`);
  }
  public billingAccountsIndex(
    params: { ids: number[] | string[] } = { ids: [] },
  ): Promise<ICollectionDocument<BillingAccountsResource>> {
    return this.fetch("/billing_accounts", { params });
  }

  public billingAccountsShowRequest(id: string): RemergeApiRequest<ISingularDocument<BillingAccountsResource>> {
    return request({ url: url(`/billing_accounts/${id}`) });
  }

  /** @deprecated */
  billingAccountsShow = fetchFromRequestBuilder(this.billingAccountsShowRequest);

  public billingAccountsSendStatement(id: string): Promise<ISingularDocument<BillingAccountsResource>> {
    return this.fetch(`/billing_accounts/${id}/send_statement`, { method: "PUT" });
  }

  public invoicesIndex(
    params: {
      invoiceState?: InvoiceStateValue | InvoiceStateValue[];
      ids?: string[];
      billingAccountId?: string;
    } = {},
  ): Promise<ICollectionDocument<InvoicesIndexResource>> {
    return this.fetch("/invoices", { params });
  }

  public invoicesCreate(data: InvoicesCreateRequestResource): Promise<InvoicesCreateResponseResource> {
    return this.fetch("/invoices", { body: { data }, method: "POST" }).then(response => response.data);
  }

  public invoiceShow(id: string): Promise<ISingularDocument<InvoicesIndexResource>> {
    return this.fetch(`/invoices/${id}`);
  }

  public invoiceDelete(invoiceId: string): Promise<string> {
    return this.fetch(`/invoices/${invoiceId}`, { method: "DELETE" });
  }

  public invoicesUpdate(data: InvoicesUpdateRequestResource): Promise<ISingularDocument<InvoicesIndexResource>> {
    return this.fetch(`/invoices/${data.id}`, { body: { data }, method: "PATCH" });
  }

  public creditInvoiceCreate(invoiceId: string): Promise<ISingularDocument<InvoicesIndexResource>> {
    return this.fetch(`/invoices/${invoiceId}/create_credit_invoice`, { method: "PUT" });
  }

  public invoiceCopy(invoiceId: string): Promise<ISingularDocument<InvoicesIndexResource>> {
    return this.fetch(`/invoices/${invoiceId}/copy`, { method: "POST" });
  }

  public invoiceApprove(invoiceId: string): Promise<ISingularDocument<InvoicesIndexResource>> {
    return this.fetch(`/invoices/${invoiceId}/approve`, { method: "PUT" });
  }

  public invoiceMarkPaid(invoiceId: string): Promise<ISingularDocument<InvoicesIndexResource>> {
    return this.fetch(`/invoices/${invoiceId}/mark_paid`, { method: "PUT" });
  }

  public invoicePDFUrl(invoiceId: string): Promise<string> {
    return this.fetch(`/invoices/${invoiceId}/link_to_pdf`, { method: "PUT" });
  }

  public invoiceRegeneratePDF(invoiceId: string): Promise<string> {
    return this.fetch(`/invoices/${invoiceId}/regenerate_pdf`, { method: "PUT" });
  }

  public invoiceLineItemsIndex(params: { invoiceId: string }): Promise<ICollectionDocument<InvoiceLineItemsResource>> {
    return this.fetch("/invoice_line_items", { params });
  }

  public invoiceLineItemsUpdate(
    data: InvoiceLineItemsUpdateResource,
  ): Promise<ISingularDocument<InvoiceLineItemsResource>> {
    return this.fetch(`/invoice_line_items/${data.id}`, { body: { data }, method: "PATCH" });
  }

  public invoiceLineItemsCreate(
    data: InvoiceLineItemsCreateResource,
  ): Promise<ISingularDocument<InvoiceLineItemsResource>> {
    return this.fetch(`/invoice_line_items`, { body: { data }, method: "POST" });
  }

  public invoiceLineItemsDelete(lineItem: InvoiceLineItemsResource): Promise<void> {
    return this.fetch(`/invoice_line_items/${lineItem.id}`, { method: "DELETE" });
  }

  public inventorySplitsIndexRequest(
    params: InventorySplitsIndexRequestParams,
  ): RemergeApiRequest<ICollectionDocument<InventorySplitResource>> {
    return request({ url: url("/inventory_splits", params) });
  }

  public inventorySplitsCreate(data: InventorySplitCreateResource): Promise<ISingularDocument<InventorySplitResource>> {
    return this.fetch("/inventory_splits", { body: { data }, method: "POST" });
  }

  public inventorySplitsUpdate(data: InventorySplitUpdateResource): Promise<ISingularDocument<InventorySplitResource>> {
    return this.fetch(`/inventory_splits/${data.id}`, { body: { data }, method: "PUT" });
  }

  public userListImportJobIndex(params: {
    userListImportId: string;
    limit: number;
  }): Promise<UserListImportJobIndexResponse> {
    return this.fetch("/user_list_import_jobs", { params });
  }

  public userListImportCreate(
    userListImport: UserListImportCreateResource,
  ): Promise<ISingularDocument<UserListImportResource>> {
    return this.fetch("/user_list_imports", { body: { data: userListImport }, method: "POST" });
  }

  public userListImportUpdate(
    userListImport: UserListImportUpdateRequestResource,
  ): Promise<ISingularDocument<UserListImportResource>> {
    return this.fetch(`/user_list_imports/${userListImport.id}`, {
      body: { data: userListImport },
      method: "PATCH",
    });
  }

  public userListImportShow(id: string): Promise<ISingularDocument<UserListImportResource>> {
    return this.fetch(`/user_list_imports/${id}`);
  }

  public userListImportIndex(params: { ids?: number[]; organizationId?: string; status?: Status | Status[] } = {}) {
    return this.fetch("/user_list_imports", { params }) as Promise<ICollectionDocument<UserListImportIndexResource>>;
  }

  public userListImportTrigger(id: string): Promise<void> {
    return this.fetch(`/user_list_imports/${id}/trigger_import`, { method: "PUT" });
  }

  public budgetPlansShowRequest(id: string): RemergeApiRequest<ISingularDocument<BudgetPlanShowResource>> {
    return request({ url: url(`/budget_plans/${id}`) });
  }

  /** @deprecated **/
  budgetPlansShow = fetchFromRequestBuilder(this.budgetPlansShowRequest);

  public budgetPlansUpdate(
    budgetPlanResource: BudgetPlanUpdateResource,
  ): Promise<ISingularDocument<BudgetPlanShowResource>> {
    return this.fetch(`/budget_plans/${budgetPlanResource.id}`, { body: { data: budgetPlanResource }, method: "PUT" });
  }

  public budgetPoolsIndexRequest(
    params: {
      ids?: string[];
      billingAccountId?: string;
      organizationId?: string;
      with_active_campaigns?: boolean;
    } = {},
  ): RemergeApiRequest<ICollectionDocument<BudgetPoolsIndexResource>> {
    return request<ICollectionDocument<BudgetPoolsIndexResource>>({
      url: url("/budget_pools", params),
    });
  }

  /** @deprecated **/
  budgetPoolIndex = fetchFromRequestBuilder(this.budgetPoolsIndexRequest);

  public budgetPoolsShowRequest(id: string): RemergeApiRequest<ISingularDocument<BudgetPoolsShowResource>> {
    return request({ url: `/budget_pools/${id}` });
  }

  /** @deprecated **/
  budgetPoolsShow = fetchFromRequestBuilder(this.budgetPoolsShowRequest);

  public budgetPoolUpdate(
    budgetPoolResource: BudgetPoolUpdateRequestResource,
  ): Promise<ISingularDocument<BudgetPoolsShowResource>> {
    return this.fetch(`/budget_pools/${budgetPoolResource.id}`, {
      body: { data: budgetPoolResource },
      method: "PATCH",
    });
  }

  public budgetPoolCreate(budgetPool: BudgetPoolCreateResource): Promise<ISingularDocument<BudgetPoolsShowResource>> {
    return this.fetch("/budget_pools", { body: { data: budgetPool }, method: "POST" });
  }

  public budgetPoolResetSpendRate(id: string): Promise<null> {
    return this.fetch(`/budget_pools/${id}/reset_spend_rate`, { method: "PATCH" });
  }

  public budgetPoolBatchUpdate(updates: BudgetPoolBatchUpdateRequest): Promise<BudgetPoolBatchUpdateResponse> {
    return this.fetch(`/budget_pools/batch`, { body: { data: updates }, method: "PATCH" });
  }

  public budgetSpendRateReportRequest(params: { organizationId: string }) {
    return request<ICollectionDocument<BudgetSpendRateResource>>({
      url: url("/budget_pools/spend_rate_report", params),
    });
  }

  public budgetCurrentMonthKpisRequest(params: { organizationId: string }) {
    return request<ISingularDocument<BudgetMonthlyKpisResource>>({
      url: url("/budget_pools/current_month_kpis", params),
    });
  }

  public segmentsShow(id: string) {
    return this.fetch(`/segments/${id}`) as Promise<ISingularDocument<SegmentsResource>>;
  }

  public segmentsIndexRequest(
    params: { organizationId: string } | { audienceId: string },
  ): RemergeApiRequest<ICollectionDocument<SegmentsResource>> {
    return request({ url: url("/segments", params) });
  }
  /** @deprecated */
  segmentsIndex = fetchFromRequestBuilder(this.segmentsIndexRequest);

  public segmentUsersList(id: string): Promise<{ data: string[] }> {
    return this.fetch(`/segments/${id}/users_list`, { method: "GET" });
  }

  public segmentSize(id: string): Promise<ISingularDocument<SegmentSizeResource>> {
    return this.fetch(`/segments/${id}/size`, { method: "GET" });
  }

  public experimentsIndex(params: {
    organizationId?: string;
    status?: StatusWithConcluded | StatusWithConcluded[];
    ids?: string[];
    experiment_type?: ExperimentTypeValue[];
  }) {
    return this.fetch("/experiments", { params }) as Promise<ICollectionDocument<ExperimentResource>>;
  }

  public experimentCreate(experiment: ExperimentCreateResource): Promise<ISingularDocument<ExperimentResource>> {
    return this.fetch("/experiments", { body: { data: experiment }, method: "POST" });
  }

  public forkedExperimentCreate(
    experiment: ForkedExperimentCreateResource,
  ): Promise<ISingularDocument<ForkedExperimentResource>> {
    return this.fetch("/forked_experiments", { body: { data: experiment }, method: "POST" });
  }

  public forkedExperimentUpdate(
    forked_experiment_id: string,
    winner_campaign_id: string,
  ): Promise<ISingularDocument<ForkedExperimentResource>> {
    return this.fetch(`/forked_experiments/${forked_experiment_id}`, {
      body: { winner_campaign_id },
      method: "PATCH",
    });
  }

  public forkedExperimentGetCurrentMetrics(
    experimentId: string,
  ): RemergeApiRequest<{ data: ForkedExperimentCurrentMetrics }> {
    return request({ url: url(`/forked_experiments/${experimentId}/current_metrics`) });
  }

  public experimentsShow(id: string): Promise<ISingularDocument<ExperimentResource>> {
    return this.fetch(`/experiments/${id}`);
  }

  public experimentGroupsIndex(experimentId: string) {
    return this.fetch("/experiment_groups", { params: { experiment_id: experimentId } }) as Promise<
      ICollectionDocument<ExperimentGroupResource>
    >;
  }

  public experimentGroupSplitsIndex(experimentId: string) {
    return this.fetch("/experiment_group_splits", { params: { experiment_id: experimentId } }) as Promise<
      ICollectionDocument<ExperimentGroupSplitResource>
    >;
  }

  public experimentUpdate(experiment: ExperimentUpdateRequestResource): Promise<ISingularDocument<ExperimentResource>> {
    return this.fetch(`/experiments/${experiment.id}`, {
      body: { data: experiment },
      method: "PATCH",
    });
  }
  public experimentAnalysis(
    experimentId: string,
    body?: { target_event?: string; variant_groups?: { [groupName: string]: ResourceId[] } },
  ): Promise<ExperimentResults> {
    return this.fetch(`/experiments/${experimentId}/analysis_result`, { body, method: "POST" });
  }

  public globalExperimentSetCreate(
    globalExperimentSet: GlobalExperimentSetCreateResource,
  ): Promise<ISingularDocument<GlobalExperimentSetResource>> {
    return this.fetch("/global_experiment_sets", { body: { data: globalExperimentSet }, method: "POST" });
  }

  public globalExperimentSetsIndex() {
    return this.fetch("/global_experiment_sets") as Promise<ICollectionDocument<GlobalExperimentSetResource>>;
  }

  public globalExperimentSetsShow(id: string): Promise<ISingularDocument<GlobalExperimentSetResource>> {
    return this.fetch(`/global_experiment_sets/${id}`);
  }

  public globalExperimentSetUpdate(
    globalExperimentSet: GlobalExperimentSetUpdateRequestResource,
  ): Promise<ISingularDocument<GlobalExperimentSetResource>> {
    return this.fetch(`/global_experiment_sets/${globalExperimentSet.id}`, {
      body: { data: globalExperimentSet },
      method: "PATCH",
    });
  }

  public globalExperimentCreate(
    globalExperiment: GlobalExperimentCreateResource,
  ): Promise<ISingularDocument<GlobalExperimentResource>> {
    return this.fetch("/global_experiments", { body: { data: globalExperiment }, method: "POST" });
  }

  public globalExperimentsShow(id: string): Promise<ISingularDocument<GlobalExperimentResource>> {
    return this.fetch(`/global_experiments/${id}`);
  }

  public globalExperimentsIndex(
    params: {
      status?: StatusWithConcluded | StatusWithConcluded[];
      globalExperimentSetId?: string;
      concluded?: boolean;
      show_inactive?: boolean;
    } = {},
  ): Promise<ICollectionDocument<GlobalExperimentResource>> {
    return this.fetch(`/global_experiments`, { params });
  }

  public globalExperimentCopy(id: string): Promise<ISingularDocument<GlobalExperimentResource>> {
    return this.fetch(`/global_experiments/${id}/copy`, { method: "POST" });
  }

  public globalExperimentEnqueueAnalysis(id: string): Promise<ISingularDocument<GlobalExperimentResource>> {
    return this.fetch(`/global_experiments/${id}/enqueue_analysis`, { method: "POST" });
  }

  public globalExperimentUpdate(
    globalExperiment: GlobalExperimentUpdateRequestResource,
  ): Promise<ISingularDocument<GlobalExperimentResource>> {
    return this.fetch(`/global_experiments/${globalExperiment.id}`, {
      body: { data: globalExperiment },
      method: "PATCH",
    });
  }

  public globalExperimentStop(
    globalExperiment: GlobalExperimentUpdateRequestResource,
  ): Promise<ISingularDocument<GlobalExperimentResource>> {
    return this.fetch(`/global_experiments/${globalExperiment.id}/stop`, {
      body: { data: globalExperiment },
      method: "PATCH",
    });
  }

  public globalExperimentConclude(
    globalExperiment: GlobalExperimentUpdateRequestResource,
  ): Promise<ISingularDocument<GlobalExperimentResource>> {
    return this.fetch(`/global_experiments/${globalExperiment.id}/conclude`, {
      body: { data: globalExperiment },
      method: "PATCH",
    });
  }

  public globalExperimentAnalysis(globalExperimentId: string): Promise<GlobalExperimentResults> {
    return this.fetch(`/global_experiments/${globalExperimentId}/analysis_result`);
  }

  public globalExperimentPivotUrl(globalExperimentId: string): Promise<GlobalExperimentPivotUrl> {
    return this.fetch(`/global_experiments/${globalExperimentId}/pivot_url`);
  }

  public flightSelectorsIndex() {
    return this.fetch("/flight_selectors") as Promise<ICollectionDocument<FlightSelectorResource>>;
  }

  public getImpactDeviceModelGroups(): Promise<ExperimentDeviceModelGroups> {
    return this.fetch(`/impact_device_model_groups`);
  }

  public interventionsIndex(experimentId: string): Promise<ICollectionDocument<InterventionResource>> {
    return this.fetch("/interventions", { params: { experiment_id: experimentId } });
  }

  public interventionsUpdate(
    intervention: InterventionUpdateRequestResource,
  ): Promise<ISingularDocument<InterventionResource>> {
    return this.fetch(`/interventions/${intervention.id}`, {
      body: { data: intervention },
      method: "PATCH",
    });
  }

  public interventionsCreate(intervention: InterventionCreateResource): Promise<InterventionResource> {
    return this.fetch(`/interventions`, { body: { data: intervention }, method: "POST" }).then(
      response => response.data,
    );
  }

  public interventionsDelete(intervention: InterventionResource): Promise<void> {
    return this.fetch(`/interventions/${intervention.id}`, { method: "DELETE" });
  }

  public predictionFlightsIndex(
    params: {
      ids?: string[];
      status?: StatusWithConcluded | StatusWithConcluded[];
      globalExperimentId?: string;
    } = {},
  ): Promise<ICollectionDocument<PredictionFlightResource>> {
    return this.fetch("/prediction_flights", { params });
  }

  public predictionFlightsCreate(
    predictionFlight: PredictionFlightCreateRequestResource,
  ): Promise<PredictionFlightCreateResponseResource> {
    return this.fetch("/prediction_flights", { body: { data: predictionFlight }, method: "POST" }).then(
      response => response.data,
    );
  }

  public predictionFlightsShow(id: string): Promise<ISingularDocument<PredictionFlightResource>> {
    return this.fetch(`/prediction_flights/${id}`);
  }

  public predictionFlightsUpdate(
    predictionFlight: PredictionFlightUpdateRequestResource,
  ): Promise<ISingularDocument<PredictionFlightResource>> {
    return this.fetch(`/prediction_flights/${predictionFlight.id}`, {
      body: { data: predictionFlight },
      method: "PATCH",
    });
  }

  public predictionFlightsDelete(id: string): Promise<void> {
    return this.fetch(`/prediction_flights/${id}`, { method: "DELETE" });
  }

  public predictionFlightCopy(predictionFlightId: string): Promise<ISingularDocument<PredictionFlightResource>> {
    return this.fetch(`/prediction_flights/${predictionFlightId}/copy`, { method: "POST" });
  }

  public predictorConfigsIndex(predictionFlightId: string): Promise<ICollectionDocument<PredictorConfigResource>> {
    return this.fetch("/predictor_configs", { params: { prediction_flight_id: predictionFlightId } });
  }

  public predictorConfigsUpdate(
    predictorConfig: PredictorConfigUpdateRequestResource,
  ): Promise<ISingularDocument<PredictorConfigResource>> {
    return this.fetch(`/predictor_configs/${predictorConfig.id}`, {
      body: { data: predictorConfig },
      method: "PATCH",
    });
  }

  public predictorConfigsCreate(
    predictorConfig: PredictorConfigCreateRequestResource,
  ): Promise<PredictorConfigResource> {
    return this.fetch(`/predictor_configs`, { body: { data: predictorConfig }, method: "POST" }).then(
      response => response.data,
    );
  }

  public predictorConfigsDelete(predictorConfig: PredictorConfigResource): Promise<void> {
    return this.fetch(`/predictor_configs/${predictorConfig.id}`, { method: "DELETE" });
  }

  public sessionsCreate(email: string, password: string): Promise<ISessionCreateAttributes> {
    return this.fetch("/users/sign_in", {
      body: {
        data: {
          type: "user",
          attributes: {
            email,
            password,
          },
        },
      },
      method: "POST",
    });
  }

  public sessionsDelete(): Promise<void> {
    return this.fetch("/users/sign_out", { method: "DELETE" });
  }

  public rulesIndexRequest(
    params:
      | { ids: string[] }
      | {
          campaignId?: string | string[];
          audienceId?: string;
          name?: string;
          rgt_hand?: string;
          inventorySplitId?: string | string[];
        },
  ): RemergeApiRequest<ICollectionDocument<RulesIndexResource>> {
    return request({ url: url("/rules", params) });
  }

  /** @deprecated **/
  rulesIndex = fetchFromRequestBuilder(this.rulesIndexRequest);

  public rulesUpdate(
    rule: PersistedResourceObject<RuleUpdateAttributes>,
  ): Promise<ISingularDocument<RulesIndexResource>> {
    return this.fetch(`/rules/${rule.id}`, { body: { rule: rule.attributes }, method: "PATCH" });
  }

  public rulesCreate(data: RuleCreateResource): Promise<ISingularDocument<RulesIndexResource>> {
    return this.fetch(`/rules`, { body: { data }, method: "POST" });
  }

  public rulesDelete(rule: RulesIndexResource): Promise<void> {
    return this.fetch(`/rules/${rule.id}`, { method: "DELETE" });
  }

  public rulesSet(
    campaign_id: string,
    rules_set: RulesSetUpdateResource,
  ): Promise<ICollectionDocument<RulesSetUpdateResponse>> {
    return this.fetch(`/campaigns/${campaign_id}/rules_set`, {
      body: rules_set,
      method: "POST",
    });
  }

  public categoriesIndexRequest(): RemergeApiRequest<ICollectionDocument<CategoriesIndexResource>> {
    return request({ url: `/categories` });
  }

  /** @deprecated */
  categoriesIndex = fetchFromRequestBuilder(this.categoriesIndexRequest);

  public publisherBlocklistIndex(params: {
    supplyPartnerIds?: string[];
  }): Promise<ICollectionDocument<PublisherBlocklistIndexResource>> {
    return this.fetch(`/publisher_blocklist_entries`, { params });
  }

  public publisherBlocklistCreate(
    data: PublisherBlocklistCreateResource,
  ): Promise<ISingularDocument<PublisherBlocklistIndexResource>> {
    return this.fetch(`/publisher_blocklist_entries`, { body: { data }, method: "POST" });
  }

  public publisherBlocklistBatchCreate(
    blocklistEntries: PublisherBlocklistBatchCreateRequest,
  ): Promise<PublisherBlocklistBatchCreateResponse> {
    return this.fetch(`/publisher_blocklist_entries/batch`, { body: { data: blocklistEntries }, method: "POST" });
  }

  public publisherBlocklistBatchDelete(ids: number[]): Promise<{ deleted_rows: number }> {
    return this.fetch(`/publisher_blocklist_entries/batch_delete`, {
      body: { data: { ids } },
      method: "POST",
    });
  }

  public deviceBlocklistIndex(): Promise<ICollectionDocument<DeviceBlocklistIndexResource>> {
    return this.fetch(`/device_blocklist_entries`);
  }

  public deviceBlocklistCreate(
    data: DeviceBlocklistCreateResource,
  ): Promise<ISingularDocument<DeviceBlocklistIndexResource>> {
    return this.fetch(`/device_blocklist_entries`, { body: { data }, method: "POST" });
  }

  public deviceBlocklistBatchCreate(
    blocklistEntries: DeviceBlocklistBatchCreateRequest,
  ): Promise<DeviceBlocklistBatchCreateResponse> {
    return this.fetch(`/device_blocklist_entries/batch`, { body: { data: blocklistEntries }, method: "POST" });
  }

  public deviceBlocklistBatchDelete(ids: number[]): Promise<{ deleted_rows: number }> {
    return this.fetch(`/device_blocklist_entries/batch_delete`, {
      body: { data: { ids } },
      method: "POST",
    });
  }

  public ipBlocklistIndex(): Promise<ICollectionDocument<IPBlocklistIndexResource>> {
    return this.fetch(`/ip_blocklist_entries`);
  }

  public ipBlocklistCreate(data: IPBlocklistCreateResource): Promise<ISingularDocument<IPBlocklistIndexResource>> {
    return this.fetch(`/ip_blocklist_entries`, { body: { data }, method: "POST" });
  }

  public ipBlocklistBatchCreate(
    blocklistEntries: IPBlocklistBatchCreateRequest,
  ): Promise<IPBlocklistBatchCreateResponse> {
    return this.fetch(`/ip_blocklist_entries/batch`, { body: { data: blocklistEntries }, method: "POST" });
  }

  public ipBlocklistBatchDelete(ids: number[]): Promise<{ deleted_rows: number }> {
    return this.fetch(`/ip_blocklist_entries/batch_delete`, {
      body: { data: { ids } },
      method: "POST",
    });
  }

  public publisherListsIndexRequest(params?: {
    with_campaigns: boolean;
  }): RemergeApiRequest<ICollectionDocument<PublisherListsIndexResource>> {
    return request({ url: url("/publisher_lists", params) });
  }

  /** @deprecated */
  public publisherListsIndex = fetchFromRequestBuilder(this.publisherListsIndexRequest);

  public publisherListsShowRequest(id: ResourceId): RemergeApiRequest<ISingularDocument<PublisherListsIndexResource>> {
    return request({ url: `/publisher_lists/${id}` });
  }

  public publisherListsCreate(
    publisherList: PublisherListCreateResource,
  ): Promise<ISingularDocument<PublisherListsIndexResource>> {
    return this.fetch("/publisher_lists", { method: "POST", body: { data: publisherList } });
  }

  public publisherListUpdate(
    data: PublisherListUpdateRequestResource,
  ): Promise<ISingularDocument<PublisherListsIndexResource>> {
    return this.fetch(`/publisher_lists/${data.id}`, { body: { data }, method: "PATCH" });
  }

  public publisherListsBatchDeleteRequest(publisherListIds: BatchDeleteResource): Promise<BatchDeleteResponse> {
    return this.fetch("/publisher_lists/batch_delete", { method: "POST", body: { data: publisherListIds } });
  }

  public publisherListEntriesIndexRequest(params: {
    publisher_list_id: ResourceId;
  }): RemergeApiRequest<ICollectionDocument<PublisherListEntriesIndexResource>> {
    return request({ url: url(`/publisher_list_entries`, params) });
  }

  public publisherListEntriesBatchCreate(
    publisherListEntries: PublisherListEntriesBatchCreateRequest,
  ): Promise<BatchCreateResponse<PublisherListEntriesIndexResource>> {
    return this.fetch("/publisher_list_entries/batch", { method: "POST", body: { data: publisherListEntries } });
  }

  public publisherListEntriesBatchDeleteRequest(
    publisherListEntryIds: BatchDeleteResource,
  ): Promise<BatchDeleteResponse> {
    return this.fetch("/publisher_list_entries/batch_delete", {
      method: "POST",
      body: { data: publisherListEntryIds },
    });
  }

  public supplyPartnersIndexRequest(
    params: {
      mode?: SupplyPartnerModeValue[];
    } = {},
  ): RemergeApiRequest<ICollectionDocument<SupplyPartnersIndexResource>> {
    return request({ url: url(`/supply_partners`, params) });
  }

  /** @deprecated */
  public supplyPartnersIndex = fetchFromRequestBuilder(this.supplyPartnersIndexRequest);

  /** @deprecated */
  public supplyPartnersShow = fetchFromRequestBuilder(this.supplyPartnersShowRequest);

  public supplyPartnersShowRequest(id: string): RemergeApiRequest<ISingularDocument<SupplyPartnersShowResource>> {
    return request({ url: `/supply_partners/${id}` });
  }

  public supplyPartnersCreate(
    data: SupplyPartnersCreateResource,
  ): Promise<ISingularDocument<SupplyPartnersShowResource>> {
    return this.fetch(`/supply_partners`, { body: { data }, method: "POST" });
  }

  public audiencesShowRequest(id: string): RemergeApiRequest<ISingularDocument<AudienceShowResource>> {
    return request({ url: `/audiences/${id}` });
  }

  public adLayoutInstancesIndexRequest(): RemergeApiRequest<ICollectionDocument<AdLayoutInstanceResource>> {
    return request({ url: url(`/ad_layout_instances`) });
  }

  public adLayoutInstanceExampleTemplatesRequest(params: {
    ids: string[];
  }): RemergeApiRequest<ICollectionDocument<AdLayoutInstanceExampleTemplateResource>> {
    return request({ url: url(`/ad_layout_instances/example_templates`, params) });
  }

  public adLayoutInstancesShowRequest(id: string): RemergeApiRequest<ISingularDocument<AdLayoutInstanceResource>> {
    return request({ url: url(`/ad_layout_instances/${id}`) });
  }

  /** @deprecated */
  audiencesShow = fetchFromRequestBuilder(this.audiencesShowRequest);

  public audiencesDelayEventTrackingPause(id: string): Promise<ISingularDocument<AudienceShowResource>> {
    return this.fetch(`/audiences/${id}/delay_event_tracking_pause`, { method: "PATCH" });
  }

  public audiencesReactivateEventTracking(id: string): Promise<ISingularDocument<AudienceShowResource>> {
    return this.fetch(`/audiences/${id}/reactivate_event_tracking`, { method: "PATCH" });
  }

  public audiencesUpdate(data: AudienceUpdateRequestResource): Promise<ISingularDocument<AudienceShowResource>> {
    return this.fetch(`/audiences/${data.id}`, { body: { data }, method: "PATCH" });
  }

  public audienceSize(id: string): Promise<ISingularDocument<AudienceSizeResource>> {
    return this.fetch(`/audiences/${id}/size`, { method: "GET" });
  }

  public audienceEventProcessingCostRequest(
    id: string,
  ): RemergeApiRequest<ISingularDocument<AudienceEventProcessingCostResource>> {
    return request({ url: url(`/audiences/${id}/event_processing_cost`) });
  }
  /** @deprecated */
  audienceEventProcessingCost = fetchFromRequestBuilder(this.audienceEventProcessingCostRequest);

  public audienceVerticalsIndex(): Promise<ICollectionDocument<AudienceVerticalsResource>> {
    return this.fetch(`/audience_verticals`);
  }

  public audienceEventUpload({
    audienceId,
    file,
    separator,
  }: {
    audienceId: string;
    file: File;
    separator: string;
  }): Promise<{ count: number }> {
    const body = {
      audience: {
        file,
        id: audienceId,
        separator,
      },
    };

    return this.fetch(`/audiences/${audienceId}/event_attribute_upload`, { body, method: "POST" });
  }

  public audienceEventUploadJobIndexRequest(params: {
    audience_id: string;
    limit?: number;
  }): RemergeApiRequest<ICollectionDocument<AudienceEventUploadJobIndexResource>> {
    return request({ url: url("/audience_event_upload_jobs", params) });
  }
  /** @deprecated */
  audienceEventUploadJobIndex = fetchFromRequestBuilder(this.audienceEventUploadJobIndexRequest);

  public creativeSetsIndexRequest(params: {
    organizationId: string;
    ids?: string[];
  }): RemergeApiRequest<CreativeSetIndexResponse> {
    return request({ url: url("/creative_sets", params) });
  }

  public creativeSetsShowRequest(id: string): RemergeApiRequest<ISingularDocument<CreativeSetResource>> {
    return request({ url: url(`/creative_sets/${id}`) });
  }

  public creativeSetsUpdate(data: CreativeSetUpdateResource): Promise<ISingularDocument<CreativeSetResource>> {
    return this.fetch(`/creative_sets/${data.id}`, { body: { data }, method: "PATCH" });
  }

  public creativeSetsPreview(
    creativeSetId: string,
    exampleTemplate: string,
  ): Promise<ISingularDocument<LinkForCreativeSetPreviewResource>> {
    return this.fetch(`/creative_sets/${creativeSetId}/preview`, {
      body: { data: { exampleTemplate } },
      method: "POST",
    });
  }

  /** @deprecated */
  creativeSetsIndex = fetchFromRequestBuilder(this.creativeSetsIndexRequest);

  public audienceAttributesIndexRequest(
    audienceId: string,
  ): RemergeApiRequest<ICollectionDocument<AudienceAttributesIndexResource>> {
    return request({ url: url("/audience_attributes", { audience_id: audienceId }) });
  }

  /** @deprecated */
  audienceAttributesIndex = fetchFromRequestBuilder(this.audienceAttributesIndexRequest);

  public audienceEventsIndexRequest(
    audienceId: string | string[],
  ): RemergeApiRequest<ICollectionDocument<AudienceEventResource>> {
    return request({ url: url("/audience_events", { audience_id: audienceId }) });
  }
  /** @deprecated */
  audienceEventsIndex = fetchFromRequestBuilder(this.audienceEventsIndexRequest);

  public audienceEventUpdate(
    data: AudienceEventUpdateRequestResource,
  ): Promise<ISingularDocument<AudienceEventResource>> {
    return this.fetch(`/audience_events/${data.id}`, { body: { data }, method: "PATCH" });
  }

  public skanCampaignsIndexRequest(
    params: SkanCampaignsIndexRequestParams,
  ): RemergeApiRequest<ICollectionDocument<SkanCampaignResource>> {
    return request({ url: url("/skan_campaigns", params) });
  }

  /** @deprecated */
  skanCampaignsIndex = fetchFromRequestBuilder(this.skanCampaignsIndexRequest);

  public skanCampaignsShowRequest(id: string): RemergeApiRequest<ISingularDocument<SkanCampaignResource>> {
    return request({ url: url(`/skan_campaigns/${id}`) });
  }

  public skanCampaignsCreate(data: SkanCampaignCreateResource): Promise<ISingularDocument<SkanCampaignResource>> {
    return this.fetch(`/skan_campaigns`, { body: { data }, method: "POST" });
  }

  public skanCampaignsUpdate(data: SkanCampaignUpdateResource): Promise<ISingularDocument<SkanCampaignResource>> {
    return this.fetch(`/skan_campaigns/${data.id}`, { body: { data }, method: "PATCH" });
  }

  public audiUsersIndex(ids: string[]): Promise<ICollectionDocument<AudiUserResource>> {
    return this.fetch("/audi_users", { params: { ids } });
  }

  public usersCreate(userResource: UsersCreateRequestResource): Promise<ISingularDocument<UsersShowResource>> {
    return this.fetch("/users", { body: { data: userResource }, method: "POST" });
  }

  public supplyPartnersUpdate(
    supplyPartnersResource: SupplyPartnersUpdateRequestResource,
  ): Promise<ISingularDocument<SupplyPartnersShowResource>> {
    return this.fetch(`/supply_partners/${supplyPartnersResource.id}`, {
      body: { data: supplyPartnersResource },
      method: "PATCH",
    });
  }

  public organizationsUpdate(
    organizationResource: OrganizationsUpdateRequestResource,
  ): Promise<ISingularDocument<OrganizationsShowResource>> {
    return this.fetch(`/organizations/${organizationResource.id}`, {
      body: { data: organizationResource },
      method: "PATCH",
    });
  }

  public usersUpdate(userResource: UsersUpdateRequestResource): Promise<ISingularDocument<UsersShowResource>> {
    return this.fetch(`/users/${userResource.id}`, { body: { data: userResource }, method: "PATCH" });
  }

  public adLabelsIndexRequest(params: {
    organizationId?: string;
    ids?: string[];
    status?: Status;
    campaignId?: string | string[];
  }): RemergeApiRequest<ICollectionDocument<AdLabelsIndexResource>> {
    return request({ url: url("/ad_labels", params) });
  }

  /** @deprecated */
  adLabelsIndex = fetchFromRequestBuilder(this.adLabelsIndexRequest);

  public adLabelsCreate(adLabel: AdLabelsCreateResource): Promise<ISingularDocument<AdLabelsIndexResource>> {
    return this.fetch("/ad_labels", { body: { data: adLabel }, method: "POST" });
  }

  public adLabelsBatchUpdate(updates: AdLabelsBatchUpdateRequest): Promise<AdLabelsBatchUpdateResponse> {
    return this.fetch(`/ad_labels/batch`, { body: { data: updates }, method: "PATCH" });
  }

  public adAssetsCreate(adAssetResource: AdAssetCreateResource): Promise<ISingularDocument<AdAssetResource>> {
    const legacyRequestBody = { asset: adAssetResource.attributes };
    return this.fetch("/ad_assets", { body: legacyRequestBody, method: "POST" });
  }

  public adAssetsIndexRequest({
    ids,
    asset_type,
  }: {
    ids: string[];
    asset_type?: AdAssetType[];
  }): BatchedCollectionGetRequest<AdAssetResource> {
    const chunkedIds = chunk(ids, DEFAULT_BATCH_SIZE);
    return {
      requests: chunkedIds.map(idsChunk => {
        return request({ url: url(`/ad_assets`, { asset_type, ids: idsChunk }) });
      }),
    };
  }
  /** @deprecated */
  public adAssetsIndex(params: {
    ids: string[];
    asset_type?: AdAssetType[];
  }): Promise<ICollectionDocument<AdAssetResource>> {
    return this.fetchBatchedCollectionGetRequest(this.adAssetsIndexRequest(params));
  }

  public adAssetsShowRequest(id: string): RemergeApiRequest<ISingularDocument<AdAssetResource>> {
    return request({ url: url(`/ad_assets/${id}`) });
  }
  /** @deprecated */
  adAssetsShow = fetchFromRequestBuilder(this.adAssetsShowRequest);

  public adAssetsUpdate(adAssetResource: AdsAssetUpdateRequestResource): Promise<ISingularDocument<AdAssetResource>> {
    const legacyRequestBody = { asset: adAssetResource.attributes };
    return this.fetch(`/ad_assets/${adAssetResource.id}`, { body: legacyRequestBody, method: "PATCH" });
  }

  public forecastsCreate(forecastResource: ForecastCreateResource): Promise<ISingularDocument<ForecastResource>> {
    return this.fetch("/forecasts", { body: { data: forecastResource }, method: "POST" });
  }

  public getBudgetPoolsOverview(params: { organizationId: string; with_active_campaigns?: boolean }) {
    return this.fetch("/budget_pools_overview", { params }) as Promise<
      ICollectionDocument<BudgetPoolsOverviewResource>
    >;
  }

  public platformFeeDealsIndex(params: { organizationId: string }) {
    return this.fetch("/platform_fee_deals", { params }) as Promise<ICollectionDocument<PlatformFeeDealResource>>;
  }

  public platformFeeDealCreate(
    platformFeeDeal: PlatformFeeDealCreateResource,
  ): Promise<ISingularDocument<PlatformFeeDealResource>> {
    return this.fetch("/platform_fee_deals", { body: { data: platformFeeDeal }, method: "POST" });
  }

  public platformFeeDealsShow(id: string): Promise<ISingularDocument<PlatformFeeDealResource>> {
    return this.fetch(`/platform_fee_deals/${id}`);
  }

  public platformFeeDealsUpdate(
    platformFeeDeal: PlatformFeeDealUpdateResource,
  ): Promise<ISingularDocument<PlatformFeeDealResource>> {
    return this.fetch(`/platform_fee_deals/${platformFeeDeal.id}`, {
      body: { data: platformFeeDeal },
      method: "PATCH",
    });
  }

  public spendTiersIndex(platformFeeDealId: string): Promise<ICollectionDocument<SpendTierResource>> {
    return this.fetch("/spend_tiers", { params: { platform_fee_deal_id: platformFeeDealId } });
  }

  public spendTiersUpdate(spendTier: SpendTierUpdateResource): Promise<ISingularDocument<SpendTierResource>> {
    return this.fetch(`/spend_tiers/${spendTier.id}`, { body: { data: spendTier }, method: "PATCH" });
  }

  public spendTiersCreate(spendTier: SpendTierCreateResource): Promise<SpendTierResource> {
    return this.fetch(`/spend_tiers`, { body: { data: spendTier }, method: "POST" }).then(response => response.data);
  }

  public spendTiersDelete(spendTier: SpendTierResource): Promise<void> {
    return this.fetch(`/spend_tiers/${spendTier.id}`, { method: "DELETE" });
  }

  public feedsIndex({
    organizationId,
    source,
  }: {
    organizationId: string;
    source?: string;
  }): Promise<ICollectionDocument<FeedIndexResource>> {
    return this.fetch("/feeds", { params: { organizationId, source } });
  }

  public feedsPreview({ feedId }: { feedId: string }): Promise<ICollectionDocument<FeedsPreview>> {
    return this.fetch(`/feeds/${feedId}/preview`);
  }

  public impactReportCreate(
    impactReport: ImpactReportCreateResource,
  ): Promise<ISingularDocument<ImpactReportResource>> {
    return this.fetch(`/impact_reports`, {
      body: { data: impactReport },
      method: "POST",
    });
  }

  public eventApiUsersIndex(): Promise<ICollectionDocument<EventApiUserResource>> {
    return this.fetch("/event_api_users", {});
  }

  public eventApiUsersUpdate(
    eventApiUser: EventApiUserUpdateResource,
  ): Promise<ISingularDocument<EventApiUserResource>> {
    return this.fetch(`/event_api_users/${eventApiUser.id}`, { body: { data: eventApiUser }, method: "PATCH" });
  }

  public eventApiUsersCreate(
    eventApiUser: EventApiUserCreateResource,
  ): Promise<ISingularDocument<EventApiUserResource>> {
    return this.fetch(`/event_api_users`, {
      body: { data: eventApiUser },
      method: "POST",
    });
  }

  public eventApiUsersShow(id: string): Promise<ISingularDocument<EventApiUserShowResource>> {
    return this.fetch(`/event_api_users/${id}`);
  }

  public eventApiUsersDelete(id: string): Promise<void> {
    return this.fetch(`/event_api_users/${id}`, { method: "DELETE" });
  }

  public bidMultipliersImportJobsIndex(): Promise<ICollectionDocument<BidMultipliersImportJobResource>> {
    return this.fetch("/bid_multipliers_import_jobs", {});
  }

  public bidMultipliersImportJobCreate({
    file,
  }: {
    file: File;
  }): Promise<ISingularDocument<BidMultipliersImportJobResource>> {
    return this.fetch(`/bid_multipliers_import_jobs`, {
      body: { bid_multipliers_import_job: { file } },
      method: "POST",
    });
  }

  public csvUpload<Resource extends PersistedResourceObject<any, any>>({
    file,
    apiEndpoint,
    resource,
    mode,
  }: {
    file: File;
    apiEndpoint: string;
    resource: string;
    mode?: CsvUploadType;
  }): Promise<CsvUpload<Resource>> {
    return this.fetch(apiEndpoint, {
      body: { [resource]: { file, mode } },
      method: "POST",
    });
  }

  public async getVersions(params: {
    organization_id: string;
    start_time: string;
    end_time: string;
    campaign_ids: string[];
    budget_ids: string[];
  }): Promise<ICollectionDocument<VersionResource>> {
    return this.fetch("/versions", { params });
  }

  // TODO: MAKE more specific name/endpoint
  public clientDashboardOverviewRequest(organizationId: string, startDate: string, endDate: string) {
    return request<DruidOverviewResponse<OverviewAttributes>>({
      url: `/client_dashboard_data/overview`,
      method: "POST",
      body: { organization_id: organizationId, start_date: startDate, end_date: endDate },
    });
  }

  public clientDashboardInventoryBreakdownRequest(organizationId: string, startDate: string, endDate: string) {
    return request<DruidResponse<InventoryBreakdownAttributes>>({
      url: `/client_dashboard_data/inventory_breakdown`,
      method: "POST",
      body: { organization_id: organizationId, start_date: startDate, end_date: endDate },
    });
  }

  public clientDashboardCampaignBreakdownRequest(organizationId: string, startDate: string, endDate: string) {
    return request<DruidResponse<CampaignBreakdownAttributes>>({
      url: `/client_dashboard_data/campaign_breakdown`,
      method: "POST",
      body: { organization_id: organizationId, start_date: startDate, end_date: endDate },
    });
  }

  public clientDashboardAdTypeBreakdownRequest(organizationId: string, startDate: string, endDate: string) {
    return request<DruidResponse<AdTypeBreakdownAttributes>>({
      url: `/client_dashboard_data/ad_type_breakdown`,
      method: "POST",
      body: { organization_id: organizationId, start_date: startDate, end_date: endDate },
    });
  }

  public requestPasswordReset(email: string): Promise<void> {
    return this.fetch("/users/password", {
      body: {
        data: {
          type: "user",
          attributes: {
            email,
          },
        },
      },
      method: "POST",
    });
  }

  public resetPassword(
    resetPasswordToken: string,
    password: string,
    passwordConfirmation: string,
  ): Promise<ISingularDocument<UsersShowResource>> {
    return this.fetch("/users/password", {
      body: {
        data: {
          type: "user",
          attributes: {
            password,
            password_confirmation: passwordConfirmation,
            reset_password_token: resetPasswordToken,
          },
        },
      },
      method: "PUT",
    });
  }

  public commandPaletteSearch(query: string, organization_id: ResourceId): Promise<SearchResult> {
    return this.fetch("/search", { params: { query, organization_id } });
  }

  public fetch = <T = any>(
    path: string,
    { params, body, method = "GET" }: { params?: object; body?: object; method?: Method } = {},
  ): Promise<T> => {
    const urlString = compact([path, params && encodeQueryParams(params)]).join("?");
    return this.httpService.fetch(urlString, {
      body,
      method,
    });
  };

  public fetchRequest = <Response>(request: RemergeApiRequest<Response>): Promise<Response> => {
    return this.httpService.fetch(request.url, {
      body: request.body,
      method: request.method,
    });
  };

  public fetchBatchedCollectionGetRequest = async <Resource extends PersistedResourceObject<any, any>>(
    batchRequest: BatchedCollectionGetRequest<Resource>,
  ): Promise<ICollectionDocument<Resource>> => {
    const responses = await Promise.all(batchRequest.requests.map(this.fetchRequest));
    const resources = flatMap(responses, response => response.data);
    return { data: resources };
  };
}

export type InventorySplitsIndexRequestParams = {
  campaignId: string;
  status?: Status | Status[];
  showInactive?: boolean;
};

export type SkanCampaignsIndexRequestParams = {
  audienceId: string | string[];
};

export type BidMultipliersIndexRequestParams =
  | {
      budget_pool_ids: string[];
    }
  | {
      campaign_ids: string[];
    };
